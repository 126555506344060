import { SystemColors } from "@snackpass/design-system";
import {
    ChargeType,
    IInStoreCharge,
    IPurchase,
    IStripeCharge,
} from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import { capitalize } from "lodash";
import { styled } from "styled-components";

import { Text } from "./ui";

type PurchasePayment =
    | IPurchase["charges"][number]
    | IPurchase["credits"][number];

type Props = { purchase: IPurchase };

export function Payments({ purchase }: Props) {
    if (!purchase.charges.length && !purchase.credits.length) return null;

    return (
        <>
            <Divider />
            <Header>Payments</Header>
            {purchase.charges.map((charge, i) => (
                <Payment key={i} payment={charge} index={i} />
            ))}
            {purchase.credits.map((credit, i) => (
                <Payment key={i} payment={credit} index={i} />
            ))}
        </>
    );
}

function Payment({
    payment,
    index,
}: {
    payment: PurchasePayment;
    index: number;
}) {
    return (
        <PaymentContainer>
            <PaymentLine>
                <LineItemText semibold>
                    #{index + 1} {paymentLabel(payment)}
                </LineItemText>
                <LineItemText>
                    {Dinero({ amount: payment._amount }).toFormat()}
                </LineItemText>
            </PaymentLine>
            <PaymentSubtitle payment={payment} />
        </PaymentContainer>
    );
}

function cashLabel(charge: IInStoreCharge) {
    return `Cash ${charge.hasPaidCash ? "" : "(Unconfirmed)"}`;
}

function stripeLabel(charge: IStripeCharge) {
    return "Card";
}

function paymentLabel(payment: PurchasePayment) {
    if ("chargeType" in payment) {
        switch (payment.chargeType) {
            case ChargeType.inStore: {
                return cashLabel(payment);
            }
            case ChargeType.stripe:
                return stripeLabel(payment);
            case ChargeType.checkout:
                return "Card";
            case ChargeType.thirdParty:
                return "Third-party";
            case ChargeType.zage:
                return "Card";
            default:
                return "Card";
        }
    } else {
        switch (payment.creditType) {
            case "GlobalCredit":
                return "Snackpass Credit";
            case "StoreCredit":
                return "Store Credit";
            case "GiftCardCredit":
                return "Gift Card";
        }
    }
}

function PaymentSubtitle({ payment }: { payment: PurchasePayment }) {
    if (!("chargeType" in payment)) {
        return null;
    }

    if (payment.chargeType === ChargeType.inStore) {
        return payment.cashTenderedCents && payment.changeAmountCents ? (
            <>
                <LineItemText>
                    Paid:{" "}
                    {Dinero({ amount: payment.cashTenderedCents }).toFormat()}
                </LineItemText>
                <LineItemText>
                    Change:{" "}
                    {Dinero({ amount: payment.changeAmountCents }).toFormat()}
                </LineItemText>
            </>
        ) : null;
    } else if (payment.chargeType === ChargeType.stripe) {
        return payment._brand && payment._last4 ? (
            <LineItemText>
                {capitalize(payment._brand)} •••• {payment._last4}
            </LineItemText>
        ) : null;
    } else {
        return null;
    }
}

const Divider = styled.div`
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    margin: 16px 0px;
`;

const Header = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
`;

const PaymentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;

const PaymentLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;

const LineItemText = styled(Text)<{ semibold?: boolean }>`
    color: ${SystemColors.v1.sesame};
    font-weight: 300;
    font-weight: ${(props) => (props.semibold ? "500" : "300")};
`;
